$(document).ready(function() {

	// Clients carousel
	if ($('.clients-carousel').length) {
		$('.clients-carousel').owlCarousel({
			loop: true,
			items: 2,
			autoplay: true,
			slideTransition: 'linear',
			autoplaySpeed: 2500,
			autoplayTimeout: 2500,
			center: true,
			nav: false, 
			dots: false, 
			responsive: {
				1200: {
					items: 4,
				},
				992: {
					items: 3,
				}
			},
		});	
	}

	
	document.querySelectorAll('.see-password').forEach(element => {
		const input = element.closest('.see-password-wrap').querySelector('input');
		// When the user click on the icon
		element.addEventListener('click', function() {
			// Add active class to the icon
			this.classList.toggle('active');
			// Change the type of the input
			if (input.getAttribute('type') == 'password') {
				input.setAttribute('type', 'text');
			} else {
				input.setAttribute('type', 'password');
			}
		});            
	});

});